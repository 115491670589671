.partner {
    margin-bottom: 200px;

    .container {
        background: linear-gradient(90deg, #324E59 0%, #436977 100%);
        color: #000;
        display: grid;
        justify-items: center;
        align-self: center;
    }

    .partner-list {
        max-width: $max-width;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: $distance-section-star;
        padding-bottom: $distance-section-end;
        
        .partner-card {
            display: grid;
            height: 200px;
            width: 350px;
            padding: 20px;

            .partner-text {
                background: white;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                border-radius: 8px;
                align-self: center;
                justify-content: center;
                padding: 10px;
                padding-top: 30px;
                padding-bottom: 30px;
                background-color: white;
                font-size: $text-font-size;
                font-weight: $text-font-weight;
                letter-spacing: $text-letter-spacing;
                line-height: $text-line-height;
            }
        
            img {
                background: white;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                border-radius: 8px;
                height: 200px;
                width: 300px;
            }
        }

        @media (max-width: 780px) {
            .partner-card {
                display: grid;
                justify-content: center;
                height: 200px;
                width: 300px;
                height: 100%;
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        @media (max-width: 680px) {
            .partner-card {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        @media (max-width: 580px) {
            .partner-card {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}