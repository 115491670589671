@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;0,900;1,500&display=swap");
@import "react-slideshow-image/dist/styles.css";
html, body {
  font-family: "Roboto", "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #222529;
  margin: 0px 0px 0px 0px;
}

.wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  
  .cookiepolicy {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

a {
  color: #0B9ED9;
  text-decoration: none;
  transition: all 0.05s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #324E59;
}


// Define base font size for easy scaling
$base-font-size: 16px;

// Define responsive breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Define letter spacing, line height, and margin
$h1-letter-spacing: 0.5px;
$h1-line-height: 1.2;
$distance-title-to-text: 16px;

// CSS variables for fluid typography
:root {
  --min-vw: 320;
  --max-vw: 1200;
}

// Mixin for common heading styles
@mixin heading-styles($min-font-size, $max-font-size) {
  font-size: $min-font-size;
  letter-spacing: $h1-letter-spacing;
  line-height: $h1-line-height;
  margin: 0px 0px $distance-title-to-text 0px;

  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
    font-size: calc(#{$min-font-size} + (#{$max-font-size} - #{$min-font-size}) * ((100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw))));
  }

  @media (min-width: $breakpoint-lg) {
    font-size: $max-font-size;
  }
}

// Responsive headings
h1 {
  @include heading-styles(28px, 40px);
}

h2 {
  @include heading-styles(24px, 36px);
}

h3 {
  @include heading-styles(20px, 32px);
}

h4 {
  @include heading-styles(18px, 28px);
}

h5 {
  @include heading-styles(16px, 24px);
}

h6 {
  @include heading-styles(14px, 16px);
}

p {
  font-size: 20px;
  line-height: 1.2;
  margin: 0px;
}