@media (min-width: 920px) {
.quoteslider {
    background: white;
    width: 920px;
    justify-self: center;

    .quote-icon {
        color: #6DBFF2;
        font-size: 64px;
    }

    .inner-text {
        padding: 20px;
        font-weight: 600;
    }

    .row {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .each-slide-effect {
        margin-left: 45px;
        margin-right: 45px;
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-color: #324E59;
        border-radius: 20px;
        box-shadow: 0 0 6px 10px rgba(0, 0, 0, 0.06);
    
        h3 {
            margin-top: 0px;
        }

        .slide-inner {
            margin-top: 40px;
            margin-bottom: 40px;
            margin-left: 40px;
            margin-right: 40px;
        }

        .carousel-image-container {
            display: flex;
            align-items: center;
            justify-content: left;

            .carousel-image {
                border-radius: 50%;
                max-width: 20%;
                height: auto;

                .carousel-image-text {
                    margin-left: 15px;
                }
            }
        }
    }
}
}
@media (max-width: 920px) {
    .quoteslider {
        display: none;
    }
}