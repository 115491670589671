.button {
    background: $color-theme-5;
    border: 2px solid $color-theme-5;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: $color-linear-gradient-dark;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}
  
.button:hover,
.button:active {
    background-color: initial;
    background-position: 0 0;
    font-weight: 600;
    color: $color-theme-5;
}
  
.button:active {
    opacity: .5;
}