$color-white: #ffffff;
$color-dark: #222529;
$color-theme-1: #6DBFF2;
$color-theme-2: #C4E1F2;
$color-theme-3: #0B9ED9;
$color-theme-4: #66CAF2;
$color-theme-5: #324E59;
$color-lightgray: #f0f0f0;
$color-gray: #70707066;
$color-darkgray: #bbbbbb;
$color-darkgray-extra: #616365;
$color-shadow: rgba(0, 0, 0, 0.06);
$color-linear-gradient-light: #f5f5f5;
$color-linear-gradient-dark: #e6e6e6;

$max-width: 1280px;
$section-max-width: 1280px;
$half-section-max-width: 600px;
$breaking-point-resolution: 920px;
$breaking-point-resolution-navbar-hamburger: 900px;
$breaking-point-resolution-contact-page: 1279px;
$desktop-resolution-section-max-width: 600px;
$mobile-resolution-section-max-width: 600px;
$desktop-resolution-image-max-width: 400px;
$mobile-resolution-image-max-width: 300px;
$section-max-width-character: 75ch;
$half-section-max-width-character: 75ch;

$full-image-section-height: 60vh;

$hero-section-height: 85vh;
$hero-section-body-margin: 20px;
$hero-section-body-position-x: center;
$hero-section-body-position-y: center;
$hero-section-body-content-position-x: flex-start;
$hero-section-body-content-position-y: center;


$h1-margin: 20px;
$h2-margin: 20px;
$h3-margin: 20px;
$text-margin: 20px;
$image-margin: 20px;
$section-margin: 20px;
$h1-padding: 20px;
$h2-padding: 20px;
$h3-padding: 20px;
$text-padding: 20px;
$image-padding: 20px;
$section-padding: 20px;

$distance-image-to-title: 16px;
$distance-title-to-title: 16px;
$distance-title-to-text: 16px;
$distance-text-to-text: 16px;
$distance-text-to-button: 32px;
$distance-section-star: 60px;
$distance-section-end: 60px;
$distance-section-star-and-section-end: 16px;
$full-distance-section-star-and-section-end: 32px;

$navbar-font-weight: 500;
$navbar-font-size: 20px;
$navbar-line-height: 1.2;
$navbar-font-weight-mobile: 500;
$navbar-font-size-mobile: 20px;
$navbar-line-height-mobile: 1.2;

$footer-font-weight: 400;
$footer-font-size: 16px;
$footer-line-height: 1.2;
$footer-font-weight-mobile: 400;
$footer-font-size-mobile: 16px;
$footer-line-height-mobile: 1.2;

$line-height: 0px;
$line-length: 0px;

$hero-letter-spacing: 0px;
$hero-line-height: 1.2;
$hero-word-spacing: 0px;
$hero-font-size: 54px;
$hero-font-weight: 700;

$hero-mobile-letter-spacing: 0px;
$hero-mobile-line-height: 1.2;
$hero-mobile-word-spacing: 0px;
$hero-mobile-font-size: 40px;
$hero-mobile-font-weight: 700;

$h1-letter-spacing: 0px;
$h1-line-height: 1.2;
$h1-word-spacing: 0px;
$h1-font-size: 40px;
$h1-font-weight: 700;

$h2-letter-spacing: 0px;
$h2-line-height: 1.2;
$h2-word-spacing: 0px;
$h2-font-size: 30px;
$h2-font-weight: 600;

$h3-letter-spacing: 0px;
$h3-line-height: 1.2;
$h3-word-spacing: 0px;
$h3-font-size: 25px;
$h3-font-weight: 500;

$text-letter-spacing: 0px;
$text-line-height: 1.4;
$text-word-spacing: 0px;
$text-font-size: 20px;
$text-font-weight: 400;