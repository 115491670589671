.navbar{
    .active {
        min-height: 4rem;
        width: 100%;
        position: fixed;
        top: 0px;
        transition: 0.3s linear;
        display: flex;
        justify-content:stretch;
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        z-index: 60;
    }

    .activeTransparent {
        min-height: 4rem;
        width: 100%;
        position: fixed;
        top: 0px;
        transition: 0.3s linear;
        display: flex;
        justify-content:stretch;
        align-items: center;
        z-index: 60;
        background-color: rgba(255, 255, 255, 0);
    }

    .activeTransparentWhite {
        min-height: 4rem;
        width: 100%;
        position: fixed;
        top: 0px;
        transition: 0.3s linear;
        display: flex;
        justify-content:stretch;
        align-items: center;
        z-index: 60;
        background-color: #c4e1f2c2;
    
        .navigation-menu li a {
            text-decoration: none;
            font-weight: $navbar-font-weight;
            font-size: $navbar-font-size;
            line-height: $navbar-line-height;
            display: block;
            width: 100%;
            color: #324E59;
        }
    }
    
    .hidden {
        height: 4rem;
        width: 100%;
        z-index: 60;
        position: fixed;
        top: -80px;
        transition: 0.3s linear;
    }
    
    .brand-name {
        text-decoration: none;
        color: black;
        font-size: 1.3rem;
        margin-left: 1rem;
    }

    .navigation {
        position: relative;
        display: flex;
        align-items: center;
        height: 60px;
        width: 100%;
        padding: 0.5rem 0rem;
        background-color: #fff;
        color: #324E59;
        box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
        z-index: 50;
    }

    @media (min-width: 940px) {
        .navbar-content {
            width: $max-width;
            min-width: 40vw;
            display: grid;
            grid-template-columns: 1fr 3fr 1fr;
            align-items: center;
            justify-self: flex-end;
            margin: 0 auto;
            .navbar-button {
                display: grid;
                justify-content: flex-end;

                .button {
                    display: grid;
                    justify-content: flex-end;
                    margin-right: 20px;
                    display: block;
                    display: grid;
                }
            }
        }

        .navigation-menu {
            display: grid;
            justify-self: center;
            height: 64px;

            .hamburger {
                display: none;
            }
        }

        .navigation-menu ul {
            display: flex;
            padding: 0;
        }

        .navigation-menu li {
            list-style-type: none;
            margin: 0 1rem;
        }

        .navigation-menu li a {
            text-decoration: none;
            font-weight: $navbar-font-weight;
            font-size: $navbar-font-size;
            line-height: $navbar-line-height;
            display: block;
            width: 100%;
            color: #324E59;
        }

        .navigation-menu li a:hover {
            color: $color-theme-3;
        }

        .menuitem {
            color: #324E59;
            text-decoration: none;
            font-weight: $navbar-font-weight;
            font-size: $navbar-font-size;
            line-height: $navbar-line-height;

            .spacing {
                display: none;
            }

            input {
                display: none;
            }
            
            label {
                margin-left: 20px;
                margin-right: 20px;
                cursor: pointer;
            }
            
            .dropdown-content {
                display: none;
                color: $color-theme-3;
                padding: 20px 20px 20px 20px;
    
                a i {
                    display: inline;
                    margin-left: 10px;
                }
    
                a {
                    padding: 16px 0px 16px 0px;
                    margin-right: 10px;
                }
    
                i {
                    margin-right: 10px;
                }
    
                a:hover {
                    background-color: $color-theme-5;
                    border-radius: 5px;
                    border-image-width : 50px;
                    color: #fff;
                    i{
                        color: #fff;
                    }
                }
            }
        }

        .menuitem:hover {
            color: $color-theme-3;

            i {
                transform: rotate(180deg);
            }
            
            .spacing {
                display: block;
            }
    
            .dropdown-menu {
            
                ul li {
                    display:block;
                    position: absolute;
                    background-color: #fff;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px,
                        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                    margin-left: 0px;
                    padding: 20px 20px 20px 20px;
                    border-radius: 10px;
                }
            }
        }
    }

    @media (max-width: 939px) {
        .button {
            display: none;
        }

        .navigation-menu {
            position: absolute;
            min-width: 100%;

            .top-menu {
                display: none;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            .hamburger {
                --bar-width: 30px;
                --bar-height: 4px;
                --hamburger-gap: 6px;
                --background: white;
                --hamburger-margin: 20px;
                --animation-timing: 200ms ease-in-out;
                --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
                --x-width: calc(var(--hamburger-height) * 1.41421356237);

                display: block;
                margin: 0;
                

                *, *::after, *::before {
                    box-sizing: border-box;
                }

                .hamburger-menu {
                    display: flex;
                    flex-direction: column;
                    gap: var(--hamburger-gap);
                    width: max-content;
                    position: absolute;
                    top: -40px;
                    right: var(--hamburger-margin);
                    z-index: 100;
                    cursor: pointer;
                    

                    input {
                        appearance: none;
                        padding: 0;
                        margin: 0;
                        outline: none;
                        pointer-events: none;
                    }

                    input:checked {
                        opacity: 0;
                        width: 0;
                    }
                }

                .hamburger-menu:has(input:checked)::before {
                    rotate: 45deg;
                    width: var(--x-width);
                    translate: 0 calc(var(--bar-height) / -2);
                }

                .hamburger-menu:has(input:checked)::after {
                    rotate: -45deg;
                    width: var(--x-width);
                    translate: 0 calc(var(--bar-height) / 2);
                }

                .hamburger-menu:has(input:checked) + .sidebar {
                    translate: 0;
                    display: block;
                }

                .hamburger-menu::before,
                .hamburger-menu::after,
                .hamburger-menu input {
                    content: "";
                    width: var(--bar-width);
                    height: var(--bar-height);
                    background-color: $color-theme-5;
                    border-radius: 9999px;
                    transform-origin: left center;
                    transition: 
                        opacity var(--animation-timing), 
                        width var(--animation-timing), 
                        rotate var(--animation-timing), 
                        translate var(--animation-timing), 
                        background-color var(--animation-timing);
                }

                .sidebar {
                    display: none;
                    transition: translate var(--animation-timing);
                    translate: -100%;
                    margin-bottom: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
                    background-color: white;
                    height: 100dvh;
                    z-index: 100;

                    li {
                        display: grid;
                        color: $color-theme-3;

                        a {
                            display: flex;
                            align-items: center;
                            color: #324E59;
                            height: 50px;
                            width: 100%;

                            i {
                                margin-left: 8px;
                                margin-right: 8px;
                            }
                        }
                    }

                    hr {
                        opacity: 0.3;
                    }

                    .spacing {
                        display: none;
                    }

                    .menuitem {
                        a {
                            padding: 20px 20px 20px 20px;
                        }
                          
                        a:hover {
                        text-decoration: none;
                        color: $color-theme-3;
                        }

                        label {
                            padding: 20px 20px 20px 20px;
                        }

                        input, .sub-link {
                            display: none;
                        }
                        
                        label {
                            position: relative;
                            display: block;
                            cursor: pointer;
                            min-height: 50px;
                            align-content: center;
                            color: #324E59;
                            margin-left: 0px;
                        }

                        label:hover {
                            color: $color-theme-3;
                        }
                        
                        input:checked~.sub-link {
                            display: block;
                        }
                    }
                    
                    .sidebar-menu-button {
                        display: grid;
                        justify-content: center;
                        align-items: flex-end;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        
                        .sidebar-button {
                            display: grid;
                            padding-left: 10px;
                            padding-right: 10px;
                            display: block;
                            display: grid;
                            width:95dvw;
                            background: $color-theme-5;
                            border: 2px solid $color-theme-5;
                            border-radius: 1rem;
                            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
                            box-sizing: border-box;
                            color: $color-linear-gradient-dark;
                            cursor: pointer;
                            display: inline-block;
                            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 16px;
                            min-height: 40px;
                            outline: 0;
                            padding: 12px 14px;
                            text-align: center;
                            text-rendering: geometricprecision;
                            text-transform: none;
                            user-select: none;
                            -webkit-user-select: none;
                            touch-action: manipulation;
                            vertical-align: middle;
                        }
        
                        .sidebar-button:hover,
                        .sidebar-button:active {
                            background-color: initial;
                            background-position: 0 0;
                            font-weight: 600;
                            color: $color-theme-5;
                        }
                        
                        .sidebar-button:active {
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }
}