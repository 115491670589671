.sectionthreecolumns {
    .style-1 {
        background: #324E59;
        color: $color-lightgray;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
        text-align: justify;
    }
    
    .sectionbody {
        display: grid;
        justify-items: center;
    }
    
    .columns {
        display: grid;
        justify-items: center;
        padding: $distance-section-star 0px $distance-section-end 0px;
        max-width: $max-width;
    }
    
    @media (min-width: $breaking-point-resolution) {
        .columns {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas: 
            ' . columnsectiontitle .'
            'columnleft columnmiddle columnright'
            ;
        }

        h2 {
            font-size: $h2-font-size;
            font-weight: $h2-font-weight;
            letter-spacing: $h2-letter-spacing;
            line-height: $h2-line-height;
            margin: 0px 0px $distance-title-to-text 0px;
        }
    
        .columntitle {
            letter-spacing: $h3-letter-spacing;
            padding-left: $h3-padding;
            padding-right: $h3-padding;
            max-width: $half-section-max-width;
        }
        
        .columntext {
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
            padding-left: $text-padding;
            padding-right: $text-padding;
        }
    }

    @media (max-width: $breaking-point-resolution) {
        .columns {
            grid-template-columns: repeat(1, 1fr);;
            grid-template-areas: 
            'columnsectiontitle'
            'columnleft'
            'columnmiddle'
            'columnright'
            ;
            max-width: $half-section-max-width;
            padding-left: $text-padding;
            padding-right: $text-padding;

            .columnsectiontitle {
                grid-area: columnsectiontitle;
                display: grid;
            }

            .columnleft {
                grid-area: columnleft;
                display: grid;
                padding-bottom: $distance-text-to-text;
            }

            .columnmiddle {
                grid-area: columnmiddle;
                display: grid;
                padding-bottom: $distance-text-to-text;
            }
            
            .columnright {
                grid-area: columnright;
                display: grid;
            }
        }

        h2 {
            font-size: $h2-font-size;
            font-weight: $h2-font-weight;
            letter-spacing: $h2-letter-spacing;
            line-height: $h2-line-height;
            margin: 0px 0px $distance-title-to-text 0px;
        }
    
        .columntitle {
            letter-spacing: $h3-letter-spacing;
            max-width: $half-section-max-width;
        }
        
        .columntext {
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
        }
    }
    
    .columnsectiontitle {
        grid-area: columnsectiontitle;
        display: grid;
        justify-content: center;
    }

    .columnleft {
        grid-area: columnleft;
        display: grid;
        justify-content: center;
    }

    .columnmiddle {
        grid-area: columnmiddle;
        display: grid;
        justify-content: center;
    }
    
    .columnright {
        grid-area: columnright;
        display: grid;
        justify-content: center;
    }
}