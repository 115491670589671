.cloudiumservice {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'image-top'
    ;  
    
    .image-top {
        grid-area: image-top;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            justify-self: center;
            align-self: center;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            max-width: 100%;
        }
    }

    .bodySection {
        justify-self: center;
        margin-bottom: 20px;
        
        .text-section {
            grid-area: text-section;
            max-width: 800px;
            display: flex;
            margin-top: 20px;
            margin-bottom: 5px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}