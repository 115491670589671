.sectionherowithtextandbutton {
    .style-1 {
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        background: $color-theme-5;
        color: $color-lightgray;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
        text-align: justify;
    }
    
    .sectionbody {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
        'image-top'
        'body-section-background'
        'body-section'
        ;
    }

    .body-section-background {
        grid-area: body-section-background;
        justify-self: $hero-section-body-position-x;
        align-self: $hero-section-body-position-y;
        grid-column: 1;
        grid-row: 1;
        z-index: 2;
        width: 100%; 
        height: 400px;
        left: 0;
        right: 0;
        background-color: $color-theme-5;
        opacity: 80%;
    }

    @media (max-width: 939px) {
        .body-section-background {
            width: 100%; 
            height: 100%;
            left: 0;
            right: 0;
            background-color: $color-theme-5;
            opacity: 80%;
        }
    }
    
    .image-top {
        grid-area: image-top;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            justify-self: center;
            align-content: start;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            position:static;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            height: $hero-section-height;
        }
    }

    .body-section {
        justify-self: $hero-section-body-position-x;
        align-self: $hero-section-body-position-y;
        grid-area: body-section;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
        width: 100%; 
        max-width: $section-max-width;

        .title-section {
            grid-area: title-section;
            display: flex;
            justify-content: $hero-section-body-content-position-x;
            align-content: $hero-section-body-content-position-y;
            margin-left: $hero-section-body-margin;
            margin-right: $hero-section-body-margin;
            max-width: 660px;

            h1 {
                font-size: $hero-font-size;
                font-weight: $hero-font-weight;
                letter-spacing: $hero-letter-spacing;
                line-height: $hero-line-height;
                margin: 0px 0px $distance-title-to-text 0px;
            }
            @media (max-width: 939px) {
                h1 {
                    font-size: $hero-mobile-font-size;
                    font-weight: $hero-mobile-font-weight;
                    letter-spacing: $hero-mobile-letter-spacing;
                    line-height: $hero-mobile-line-height;
                    margin: 0px 0px $distance-title-to-text 0px;
                }
            }
        }

        .text-section {
            grid-area: text-section;
            display: grid;
            justify-content: $hero-section-body-content-position-x;
            align-content: $hero-section-body-content-position-y;
            max-width: 660px;
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
            margin-left: $hero-section-body-margin;
            margin-right: $hero-section-body-margin;
            margin-bottom: $distance-text-to-button;
        }

        .button-section {
            grid-area: button-section;
            display: flex;
            justify-content: $hero-section-body-content-position-x;
            align-content: $hero-section-body-content-position-y;
            max-width: 660px;
            margin-left: $hero-section-body-margin;
            margin-right: $hero-section-body-margin;
            button {
                background: $color-theme-1;
                border: 2px solid $color-theme-1;
                color: $color-theme-5;
            }
            button:hover,
            button:active {
                background-color: initial;
                background-position: 0 0;
                font-weight: 600;
                color: $color-theme-1;
            }
            
            button:active {
                opacity: .5;
            }
        }
    }
}