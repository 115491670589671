.privacy-policy {
    padding-top: 70px;
    padding-bottom: 70px;
        
    .wrapper {
        display: flex;
        justify-content: center;
        padding-top: 3vh;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-bottom: 10vh;
        height: 85vh;
    }
        
    .responsive-iframe {
        max-width: 1280px;
        width: 100%;
        height: 100%;
    }
}