.footer {
    background-color: #324E59;
    color: #ffffff;
    text-align: center;
    clear: both;
    padding: 25px;
    position: relative;
    overflow: hidden;
    z-index: 50;


    p {
        font-size: 16px !important;
        line-height: 1.5 !important;
    }

    a:link, a:visited{
        color: #ffffff;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: none;
        color: $color-theme-3;
    }

    button {
        border: none;
        background: inherit;
        color: #ffffff;
        text-decoration: none;
        font-family: "Roboto", "Poppins", sans-serif;
        font-weight: $footer-font-weight-mobile;
        font-size: $footer-font-size-mobile;
        line-height: $footer-line-height-mobile;
    }

    button:hover {
        border: none;
        background: inherit;
        color: $color-theme-3;
        color: #ffffff;
        text-decoration: none;
        font-family: "Roboto", "Poppins", sans-serif;
        font-size: $footer-font-size-mobile;
        color: $color-theme-3;
    }


    @media (max-width: 920px) {
        
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: 
                'logo'
                'socialicon'
                'line'
                'address'
                'links' 
                'contact'
                'cloudiumcookisettings'
                'cloudiumcookiepolicy'
                'cloudiumprivacypolicy'
                'cloudiumcopyright'
            ;
 
            .line {
                grid-area: line;
            }
        
            .logo {
                grid-area: logo;
                margin-bottom: 5px;
                text-align: center;
            }
        
            .socialicon {
                grid-area: socialicon;
                color: #dfdfdf;
                margin-top: 15px;
                margin-bottom: 8px;
                margin: 8px;
                text-align: center;
            }
        
            .address {
                grid-area: address;
                text-align: center;
            }
            
            .links {
                grid-area: links;
                text-align: center;
                margin-top: 40px;
            }
            
            .contact {
                grid-area: contact;
                text-align: center;
                margin-top: 40px;
                margin-bottom: 40px;
            }
    
            .cloudiumcookisettings {
                grid-area: cloudiumcookisettings;
                text-align: center;
            }
            
            .cloudiumcookiepolicy {
                grid-area: cloudiumprivacypolicy;
                text-align: center;
            }

            .cloudiumprivacypolicy {
                grid-area: cloudiumprivacypolicy;
                text-align: center;
            }
            
            .cloudiumcopyright {
                grid-area: cloudiumcopyright;
                text-align: center;
            }
    }
    
    
    @media (min-width: 920px) {
            .container {
                max-width: $max-width;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-columns: 200px 200px auto 200px 270px;
                grid-template-rows: 30px 30px 170px auto;
                grid-template-areas: 
                    'logo logo logo logo socialicon'
                    'line line line line line'
                    'address address address links contact'
                    'cloudiumcookisettings cloudiumcookiepolicy cloudiumprivacypolicy cloudiumprivacypolicy cloudiumcopyright'
                ;
                margin: auto;
            }

            .logo {
                grid-area: logo;
                margin-bottom: 5px;
                margin-left: 12.5px;
                text-align: left;
            }
        
            .socialicon {
                grid-area: socialicon;
                color: #dfdfdf;
                margin: 8px;
                margin-right: 15px;
                text-align: end;
            }
        
            .line {
                grid-area: line;
                margin-top: 10px;
            }
        
            .address {
                grid-area: address;
                text-align: left;
                margin-left: 25px;
                margin-top: 10px;
                width: 250px;
            }
        
            .links {
                grid-area: links;
                text-align: left;
                width: 200px;
            }
        
            .contact {
                grid-area: contact;
                text-align: left;
                width: 200px;
                margin-left: 95px;
            }
        
            .cloudiumcookisettings {
                grid-area: cloudiumcookisettings;
                margin-left: 19px;
                text-align: left;
            }

            .cloudiumcookiepolicy {
                grid-area: cloudiumcookiepolicy;
                margin-left: 0px;
                text-align: left;
                align-self: center;
            }

            .cloudiumprivacypolicy {
                grid-area: cloudiumprivacypolicy;
                margin-left: 0px;
                text-align: left;
                align-self: center;
            }            

            .cloudiumcopyright {
                grid-area: cloudiumcopyright;
                margin-right: 25px;
                text-align: right;
                align-self: center;
            }
        }
}

