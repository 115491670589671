.sectionslidingbackground {
    background-image: url("/src/assets/images/backdrop/Slide2.jpg");
    z-index:50;
    height: 60vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-color: #ffffff;  
}
@media (max-width: 991px) {
    .sectionslidingbackground {
        background-attachment: scroll; 
    }
}