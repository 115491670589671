.sectionherowithtext {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'image-top'
    'body-section'
    ;
    color: white;  
    
    .image-top {
        grid-area: image-top;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            justify-self: center;
            align-content: start;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            position:static;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            height: $hero-section-height;
        }
    }

    .body-section {
        justify-self: $hero-section-body-position-x;
        align-self: $hero-section-body-position-y;
        grid-area: body-section;
        grid-column: 1;
        grid-row: 1;
        z-index: 1;
        width: 100%; 
        max-width: $section-max-width;

        .title-section {
            grid-area: title-section;
            display: flex;
            margin-left: $hero-section-body-margin;
            margin-right: $hero-section-body-margin;
            justify-content: $hero-section-body-content-position-x;
            align-content: $hero-section-body-content-position-y;

            h1 {
                font-size: $h1-font-size;
                font-weight: $h1-font-weight;
                letter-spacing: $h1-letter-spacing;
                line-height: $h1-line-height;
                margin: 0px 0px $distance-title-to-text 0px;
            }
        }

        .text-section {
            grid-area: text-section;
            display: flex;
            justify-content: $hero-section-body-content-position-x;
            align-content: $hero-section-body-content-position-y;
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
            margin-left: $hero-section-body-margin;
            margin-right: $hero-section-body-margin;
            margin-bottom: $distance-text-to-button;
        }
    }    
}