.sectionimagecentertextcenter {
    .style-1 {
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        background: $color-theme-5;
        color: $color-lightgray;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
    }

    .sectionbody {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
        'image-top'
        'body-background'
        'body-section'
        ;
    }

    .body-background {
        grid-area: body-background;
        justify-self: $hero-section-body-position-x;
        align-self: $hero-section-body-position-y;
        grid-column: 1;
        grid-row: 1;
        z-index: 2;
        width: 100%; 
        height: 100%;
        left: 0;
        right: 0;
        background-color: $color-theme-5;
        opacity: 80%;
    }

    .image-top {
        grid-area: image-top;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            justify-self: center;
            align-content: start;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            position:static;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            height: $full-image-section-height;
        }
    }

    .body-section {
        justify-self: center;
        align-self: center;
        margin: $distance-section-star-and-section-end $text-margin $distance-section-star-and-section-end $text-margin;
        grid-area: body-section;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
        width: 100%; 
        max-width: $half-section-max-width + 40px;

        .title-section {
            grid-area: title-section;
            display: flex;
            justify-content: center;
            margin-left: $text-margin;
            margin-right: $text-margin;
            
            h2 {
                font-size: $h2-font-size;
                font-weight: $h2-font-weight;
                letter-spacing: $h2-letter-spacing;
                line-height: $h2-line-height;
                margin: 0px 0px $distance-title-to-text 0px;
            }
        }

        .text-section-row {
            grid-area: text-section-row1;
            display: flex;
            justify-content: center;
            margin-left: $text-margin;
            margin-right: $text-margin;
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
        }
    }    
}