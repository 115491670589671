.sectionslogan {
    display: grid;

    img {
        justify-self: center;
        align-content: start;
        grid-column: 1;
        grid-row: 1;
        z-index: 1;
        max-width: 100%;
    }
    
    h1 {
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
        justify-self: center;
        align-self: center;
        margin-left: 50px;
        margin-right: 50px;
    }

    .text-frame {
        grid-column: 1;
        grid-row: 1;
        min-height: 100px;
        margin-top: 19%;
        margin-bottom: 19%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: #C4E1F2;
        opacity: 50%;  
    }
}