.career {
    display: grid;
    grid-template-columns: 1fr;
        grid-template-areas: 
        'headSection'
        'career-text'
        'comment'
        'available-work-section'
        ;

    p {
        margin: 0px auto $distance-text-to-text auto;
    }    

    .headSection {
        grid-area: headSection;
        img {
            justify-self: center;
            align-content: start;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            position:static;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            height: $full-image-section-height;
        }
    }

    .career-text {
        grid-area: career-text;
        display: flex;
        max-width: 800px;
        justify-content: center;
        align-items: center;
        padding-top: $full-distance-section-star-and-section-end;
        padding-bottom: $distance-section-star-and-section-end;
        padding-left: 20px;
        padding-right: 20px;
        justify-self: center;
        align-self: center;
    }

    @media (min-width: 600px) {
        .career-text {
            padding-top: $distance-section-star;
            padding-bottom: $distance-section-end;
        }
    }

    .comment {
        img {
            border-radius: 50%;
            max-width: 250px;
        }
    }
    
    .available-work-section {
        grid-area: available-work-section;
        display: grid;
        justify-self: center;
        max-width: 1280px;
        width: 100%;
        margin-top: $distance-section-star;
        margin-bottom: $distance-section-end;
        
        .available-work {
            display: grid;
            justify-self: center;
            justify-items: center;
            width: 100%;
            max-width: 840px;
            margin-bottom: 20px;

            .available-work-list {
                width: 100%;
            }

            .available-work-link {
                display: flex;
                flex-grow: 8;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 20px;
                margin-right: 20px;
                background: white;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                border-radius: 8px;

                .available-work-title {
                    align-self: center;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
                
                .available-work-flex-grow {
                    display: flex;
                    flex-grow: 8;
                }

                .available-work-location {
                    align-self: center;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            
            }
        }
    }

    .work-not-available-text {
        margin-left: 20px;
        margin-right: 20px;
    }
}