.sectionrightwithbutton {
    .cards {
        max-width: 1280px;
        margin: auto;
        display: grid;
        gap: 1rem;
        padding: 6vh 0vw 6vh 0vw;
    }
    
    @media (min-width: 800px) {
        .cards { grid-template-columns: 3fr 1fr; }
    }
    
    .title {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: -1.5;
        padding-bottom: 16px;
    }
    
    .text {
        font-weight: 400;
        max-width: 600px;
        padding-bottom: 32px;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
    }
    
    .image img {
        max-width: 400px;
    }
    
    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }
}