.notfound {
    display: grid;
    margin-top: 200px;
    margin-bottom: 200px;
    justify-self: center;
    align-self: center;

    p {
        justify-self: center;
    }
}