.sectionvideo {
    .slogan {
        position: fixed;
        top:30vmin;
        left: 0;
        right: 0;
        z-index: 20;
    }
    .slogan-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 21;
        background-color: #C4E1F2;
        opacity: 50%;
    }
    .slogan-text,
    .slogan-text a {
        position: relative;
        z-index: 22;
        padding: 5vh 0;
        color: #0f0f0f;
        font-size: 5.5vh;
        text-align: center;
        text-decoration: none;
        opacity: 90%;
        margin-left: $text-margin;
        margin-right: $text-margin;
    }
    .slogan-text a {
        width: 50%;
        align-items: center;
        float: left;
        display: block;
        margin: -5vh 0;
    }
    .title {
        font-size: $hero-font-size;
        font-weight: $hero-font-weight;
        letter-spacing: $hero-letter-spacing;
        line-height: $hero-line-height;
        margin: 0px 0px $distance-title-to-text 0px;
    }
    .text {
        max-width: $half-section-max-width;
        font-size: $text-font-size;
        font-weight: $text-font-weight;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
        margin: 0px auto $distance-title-to-text auto;

        p {
            margin: 0px auto $distance-text-to-text auto;
        }
    }

    @media (max-width: 939px) {
        .title {
            font-size: $hero-mobile-font-size;
            font-weight: $hero-mobile-font-weight;
            letter-spacing: $hero-mobile-letter-spacing;
            line-height: $hero-mobile-line-height;
            margin: 0px 0px $distance-title-to-text 0px;
        }
    }

    @media (max-width: $half-section-max-width) {
        .slogan {
        top:40vmin;
        }
    }

    .background-video {
        position: fixed;
        z-index: 1;
        top: 0;
        width: 100vw;
        height: 110vh;
        -o-object-fit: cover;
        object-fit: cover;
    }
}