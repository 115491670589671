@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;0,900;1,500&display=swap");
@import "react-slideshow-image/dist/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;0,900;1,500&display=swap");
@import "react-slideshow-image/dist/styles.css";
html, body {
  font-family: "Roboto", "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #222529;
  margin: 0px 0px 0px 0px;
}

.wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.wrapper .cookiepolicy {
  padding-top: 70px;
  padding-bottom: 70px;
}

a {
  color: #0B9ED9;
  text-decoration: none;
  transition: all 0.05s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #324E59;
}

:root {
  --min-vw: 320;
  --max-vw: 1200;
}

h1 {
  font-size: 28px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h1 {
    font-size: calc(28px + (40px - 28px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h2 {
    font-size: calc(24px + (36px - 24px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 36px;
  }
}

h3 {
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h3 {
    font-size: calc(20px + (32px - 20px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
  }
}

h4 {
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h4 {
    font-size: calc(18px + (28px - 18px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h4 {
    font-size: 28px;
  }
}

h5 {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h5 {
    font-size: calc(16px + (24px - 16px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h5 {
    font-size: 24px;
  }
}

h6 {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (min-width: 576px) and (max-width: 992px) {
  h6 {
    font-size: calc(14px + (16px - 14px) * (100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)));
  }
}
@media (min-width: 992px) {
  h6 {
    font-size: 16px;
  }
}

p {
  font-size: 20px;
  line-height: 1.2;
  margin: 0px;
}

.contactform {
  justify-self: center;
  width: 100%;
}
.contactform .container {
  grid-template-columns: 1fr;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}
.contactform .container p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.contactform .container input {
  width: 97%;
  height: 30px;
  margin-top: 10px;
  border-radius: 1dvh;
  border-width: 1px;
  border-style: solid;
  border-color: #c2cdd1;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
}
.contactform .container input:focus {
  border-color: #6dbff2;
  outline-color: #6dbff2;
  outline-width: 4px;
  border-width: 3px;
}
.contactform .container textarea {
  width: 97%;
  height: 160px;
  margin-top: 10px;
  border-radius: 1dvh;
  border-width: 1px;
  border-style: solid;
  border-color: #c2cdd1;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
}
.contactform .container textarea:focus {
  border-color: #6dbff2;
  outline-color: #6dbff2;
  outline-width: 4px;
  border-width: 3px;
}
.contactform .container .button-full-width {
  margin-top: 32px;
  display: grid;
  display: block;
  display: grid;
  background: #324E59;
  border: 2px solid #324E59;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #e6e6e6;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  width: 100%;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.contactform .container .button-full-width:hover,
.contactform .container .button-full-width:active {
  background-color: initial;
  background-position: 0 0;
  font-weight: 600;
  color: #324E59;
}
.contactform .container .button-full-width:active {
  opacity: 0.5;
}

.career {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "headSection" "career-text" "comment" "available-work-section";
}
.career p {
  margin: 0px auto 16px auto;
}
.career .headSection {
  grid-area: headSection;
}
.career .headSection img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  position: static;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  height: 60vh;
}
.career .career-text {
  grid-area: career-text;
  display: flex;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  justify-self: center;
  align-self: center;
}
@media (min-width: 600px) {
  .career .career-text {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.career .comment img {
  border-radius: 50%;
  max-width: 250px;
}
.career .available-work-section {
  grid-area: available-work-section;
  display: grid;
  justify-self: center;
  max-width: 1280px;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}
.career .available-work-section .available-work {
  display: grid;
  justify-self: center;
  justify-items: center;
  width: 100%;
  max-width: 840px;
  margin-bottom: 20px;
}
.career .available-work-section .available-work .available-work-list {
  width: 100%;
}
.career .available-work-section .available-work .available-work-link {
  display: flex;
  flex-grow: 8;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 8px;
}
.career .available-work-section .available-work .available-work-link .available-work-title {
  align-self: center;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.career .available-work-section .available-work .available-work-link .available-work-flex-grow {
  display: flex;
  flex-grow: 8;
}
.career .available-work-section .available-work .available-work-link .available-work-location {
  align-self: center;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.career .work-not-available-text {
  margin-left: 20px;
  margin-right: 20px;
}

.footer {
  background-color: #324E59;
  color: #ffffff;
  text-align: center;
  clear: both;
  padding: 25px;
  position: relative;
  overflow: hidden;
  z-index: 50;
}
.footer p {
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.footer a:link, .footer a:visited {
  color: #ffffff;
  text-decoration: none;
}
.footer a:hover {
  text-decoration: none;
  color: #0B9ED9;
}
.footer button {
  border: none;
  background: inherit;
  color: #ffffff;
  text-decoration: none;
  font-family: "Roboto", "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
.footer button:hover {
  border: none;
  background: inherit;
  color: #0B9ED9;
  color: #ffffff;
  text-decoration: none;
  font-family: "Roboto", "Poppins", sans-serif;
  font-size: 16px;
  color: #0B9ED9;
}
@media (max-width: 920px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "logo" "socialicon" "line" "address" "links" "contact" "cloudiumcookisettings" "cloudiumcookiepolicy" "cloudiumprivacypolicy" "cloudiumcopyright";
  }
  .footer .line {
    grid-area: line;
  }
  .footer .logo {
    grid-area: logo;
    margin-bottom: 5px;
    text-align: center;
  }
  .footer .socialicon {
    grid-area: socialicon;
    color: #dfdfdf;
    margin-top: 15px;
    margin-bottom: 8px;
    margin: 8px;
    text-align: center;
  }
  .footer .address {
    grid-area: address;
    text-align: center;
  }
  .footer .links {
    grid-area: links;
    text-align: center;
    margin-top: 40px;
  }
  .footer .contact {
    grid-area: contact;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .footer .cloudiumcookisettings {
    grid-area: cloudiumcookisettings;
    text-align: center;
  }
  .footer .cloudiumcookiepolicy {
    grid-area: cloudiumprivacypolicy;
    text-align: center;
  }
  .footer .cloudiumprivacypolicy {
    grid-area: cloudiumprivacypolicy;
    text-align: center;
  }
  .footer .cloudiumcopyright {
    grid-area: cloudiumcopyright;
    text-align: center;
  }
}
@media (min-width: 920px) {
  .footer .container {
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 200px 200px auto 200px 270px;
    grid-template-rows: 30px 30px 170px auto;
    grid-template-areas: "logo logo logo logo socialicon" "line line line line line" "address address address links contact" "cloudiumcookisettings cloudiumcookiepolicy cloudiumprivacypolicy cloudiumprivacypolicy cloudiumcopyright";
    margin: auto;
  }
  .footer .logo {
    grid-area: logo;
    margin-bottom: 5px;
    margin-left: 12.5px;
    text-align: left;
  }
  .footer .socialicon {
    grid-area: socialicon;
    color: #dfdfdf;
    margin: 8px;
    margin-right: 15px;
    text-align: end;
  }
  .footer .line {
    grid-area: line;
    margin-top: 10px;
  }
  .footer .address {
    grid-area: address;
    text-align: left;
    margin-left: 25px;
    margin-top: 10px;
    width: 250px;
  }
  .footer .links {
    grid-area: links;
    text-align: left;
    width: 200px;
  }
  .footer .contact {
    grid-area: contact;
    text-align: left;
    width: 200px;
    margin-left: 95px;
  }
  .footer .cloudiumcookisettings {
    grid-area: cloudiumcookisettings;
    margin-left: 19px;
    text-align: left;
  }
  .footer .cloudiumcookiepolicy {
    grid-area: cloudiumcookiepolicy;
    margin-left: 0px;
    text-align: left;
    align-self: center;
  }
  .footer .cloudiumprivacypolicy {
    grid-area: cloudiumprivacypolicy;
    margin-left: 0px;
    text-align: left;
    align-self: center;
  }
  .footer .cloudiumcopyright {
    grid-area: cloudiumcopyright;
    margin-right: 25px;
    text-align: right;
    align-self: center;
  }
}

.navbar .active {
  min-height: 4rem;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content: stretch;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 60;
}
.navbar .activeTransparent {
  min-height: 4rem;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content: stretch;
  align-items: center;
  z-index: 60;
  background-color: rgba(255, 255, 255, 0);
}
.navbar .activeTransparentWhite {
  min-height: 4rem;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content: stretch;
  align-items: center;
  z-index: 60;
  background-color: rgba(196, 225, 242, 0.7607843137);
}
.navbar .activeTransparentWhite .navigation-menu li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  display: block;
  width: 100%;
  color: #324E59;
}
.navbar .hidden {
  height: 4rem;
  width: 100%;
  z-index: 60;
  position: fixed;
  top: -80px;
  transition: 0.3s linear;
}
.navbar .brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navbar .navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: #324E59;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  z-index: 50;
}
@media (min-width: 940px) {
  .navbar .navbar-content {
    width: 1280px;
    min-width: 40vw;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    justify-self: flex-end;
    margin: 0 auto;
  }
  .navbar .navbar-content .navbar-button {
    display: grid;
    justify-content: flex-end;
  }
  .navbar .navbar-content .navbar-button .button {
    display: grid;
    justify-content: flex-end;
    margin-right: 20px;
    display: block;
    display: grid;
  }
  .navbar .navigation-menu {
    display: grid;
    justify-self: center;
    height: 64px;
  }
  .navbar .navigation-menu .hamburger {
    display: none;
  }
  .navbar .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navbar .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navbar .navigation-menu li a {
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    display: block;
    width: 100%;
    color: #324E59;
  }
  .navbar .navigation-menu li a:hover {
    color: #0B9ED9;
  }
  .navbar .menuitem {
    color: #324E59;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
  }
  .navbar .menuitem .spacing {
    display: none;
  }
  .navbar .menuitem input {
    display: none;
  }
  .navbar .menuitem label {
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .navbar .menuitem .dropdown-content {
    display: none;
    color: #0B9ED9;
    padding: 20px 20px 20px 20px;
  }
  .navbar .menuitem .dropdown-content a i {
    display: inline;
    margin-left: 10px;
  }
  .navbar .menuitem .dropdown-content a {
    padding: 16px 0px 16px 0px;
    margin-right: 10px;
  }
  .navbar .menuitem .dropdown-content i {
    margin-right: 10px;
  }
  .navbar .menuitem .dropdown-content a:hover {
    background-color: #324E59;
    border-radius: 5px;
    border-image-width: 50px;
    color: #fff;
  }
  .navbar .menuitem .dropdown-content a:hover i {
    color: #fff;
  }
  .navbar .menuitem:hover {
    color: #0B9ED9;
  }
  .navbar .menuitem:hover i {
    transform: rotate(180deg);
  }
  .navbar .menuitem:hover .spacing {
    display: block;
  }
  .navbar .menuitem:hover .dropdown-menu ul li {
    display: block;
    position: absolute;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin-left: 0px;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
  }
}
@media (max-width: 939px) {
  .navbar .button {
    display: none;
  }
  .navbar .navigation-menu {
    position: absolute;
    min-width: 100%;
  }
  .navbar .navigation-menu .top-menu {
    display: none;
  }
  .navbar .navigation-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .navbar .navigation-menu .hamburger {
    --bar-width: 30px;
    --bar-height: 4px;
    --hamburger-gap: 6px;
    --background: white;
    --hamburger-margin: 20px;
    --animation-timing: 200ms ease-in-out;
    --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
    --x-width: calc(var(--hamburger-height) * 1.41421356237);
    display: block;
    margin: 0;
  }
  .navbar .navigation-menu .hamburger *, .navbar .navigation-menu .hamburger *::after, .navbar .navigation-menu .hamburger *::before {
    box-sizing: border-box;
  }
  .navbar .navigation-menu .hamburger .hamburger-menu {
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: max-content;
    position: absolute;
    top: -40px;
    right: var(--hamburger-margin);
    z-index: 100;
    cursor: pointer;
  }
  .navbar .navigation-menu .hamburger .hamburger-menu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }
  .navbar .navigation-menu .hamburger .hamburger-menu input:checked {
    opacity: 0;
    width: 0;
  }
  .navbar .navigation-menu .hamburger .hamburger-menu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
  }
  .navbar .navigation-menu .hamburger .hamburger-menu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
  }
  .navbar .navigation-menu .hamburger .hamburger-menu:has(input:checked) + .sidebar {
    translate: 0;
    display: block;
  }
  .navbar .navigation-menu .hamburger .hamburger-menu::before,
  .navbar .navigation-menu .hamburger .hamburger-menu::after,
  .navbar .navigation-menu .hamburger .hamburger-menu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: #324E59;
    border-radius: 9999px;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing), rotate var(--animation-timing), translate var(--animation-timing), background-color var(--animation-timing);
  }
  .navbar .navigation-menu .hamburger .sidebar {
    display: none;
    transition: translate var(--animation-timing);
    translate: -100%;
    margin-bottom: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
    background-color: white;
    height: 100dvh;
    z-index: 100;
  }
  .navbar .navigation-menu .hamburger .sidebar li {
    display: grid;
    color: #0B9ED9;
  }
  .navbar .navigation-menu .hamburger .sidebar li a {
    display: flex;
    align-items: center;
    color: #324E59;
    height: 50px;
    width: 100%;
  }
  .navbar .navigation-menu .hamburger .sidebar li a i {
    margin-left: 8px;
    margin-right: 8px;
  }
  .navbar .navigation-menu .hamburger .sidebar hr {
    opacity: 0.3;
  }
  .navbar .navigation-menu .hamburger .sidebar .spacing {
    display: none;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem a {
    padding: 20px 20px 20px 20px;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem a:hover {
    text-decoration: none;
    color: #0B9ED9;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem label {
    padding: 20px 20px 20px 20px;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem input, .navbar .navigation-menu .hamburger .sidebar .menuitem .sub-link {
    display: none;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem label {
    position: relative;
    display: block;
    cursor: pointer;
    min-height: 50px;
    align-content: center;
    color: #324E59;
    margin-left: 0px;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem label:hover {
    color: #0B9ED9;
  }
  .navbar .navigation-menu .hamburger .sidebar .menuitem input:checked ~ .sub-link {
    display: block;
  }
  .navbar .navigation-menu .hamburger .sidebar .sidebar-menu-button {
    display: grid;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .navbar .navigation-menu .hamburger .sidebar .sidebar-menu-button .sidebar-button {
    display: grid;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    display: grid;
    width: 95dvw;
    background: #324E59;
    border: 2px solid #324E59;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #e6e6e6;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  .navbar .navigation-menu .hamburger .sidebar .sidebar-menu-button .sidebar-button:hover,
  .navbar .navigation-menu .hamburger .sidebar .sidebar-menu-button .sidebar-button:active {
    background-color: initial;
    background-position: 0 0;
    font-weight: 600;
    color: #324E59;
  }
  .navbar .navigation-menu .hamburger .sidebar .sidebar-menu-button .sidebar-button:active {
    opacity: 0.5;
  }
}

.contactinfo .style-5 {
  background: linear-gradient(170deg, #f0f0f0 62%, #324e59 30%);
  color: #000;
  padding: 140px 4vw 60px 4vw;
}
@media (max-width: 1279px) {
  .contactinfo .style-5 {
    background: linear-gradient(170deg, #f0f0f0 82%, #324e59 30%);
  }
}
.contactinfo .filler {
  background-color: #324e59;
}
.contactinfo .content-body {
  display: grid;
  justify-content: center;
  margin-bottom: 250px;
}
.contactinfo .wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr 1fr);
  grid-gap: 1vw;
  max-width: 1280px;
}
@media (min-width: 1279px) {
  .contactinfo .content-text {
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 600px;
    margin-top: 14px;
  }
  .contactinfo .content-top {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 16px 0px 32px 0px;
  }
  .contactinfo .content-bottom {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 32px;
  }
}
@media (max-width: 1279px) {
  .contactinfo .wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: "cardlecontactformft" "content-text";
  }
  .contactinfo .wrapper .contactform {
    grid-area: cardlecontactformft;
    margin-bottom: 32px;
  }
  .contactinfo .content-top {
    margin-bottom: 32px;
  }
  .contactinfo .content-bottom {
    margin-bottom: 32px;
  }
  .contactinfo .content-text {
    grid-area: content-text;
    grid-template-columns: 1fr;
    max-width: 600px;
    justify-self: center;
  }
}

.cookie-policy {
  padding-top: 70px;
  padding-bottom: 70px;
}
.cookie-policy .wrapper {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vh;
  height: 85vh;
}
.cookie-policy .responsive-iframe {
  max-width: 1280px;
  width: 100%;
  height: 100%;
}

.privacy-policy {
  padding-top: 70px;
  padding-bottom: 70px;
}
.privacy-policy .wrapper {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vh;
  height: 85vh;
}
.privacy-policy .responsive-iframe {
  max-width: 1280px;
  width: 100%;
  height: 100%;
}

.work {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "image-top" "head-section-title" "head-section-icon" "text-section" "apply-section";
}
.work h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.work h2 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 16px;
  margin: 32px 0px 16px 0px;
  padding-left: 20px;
}
.work p {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.4;
  margin: 16px 20px 16px 20px;
}
.work li {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.4;
  margin: 16px 20px 16px 0px;
}
.work .image-top {
  grid-area: image-top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work .image-top img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  position: static;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  height: 60vh;
}
.work .bodySection {
  max-width: 1280px;
  justify-self: center;
  align-self: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.work .bodySection .head-section-title {
  grid-area: head-section-icon;
  max-width: 800px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}
.work .bodySection .head-section-icon {
  grid-area: head-section-icon;
  max-width: 800px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}
.work .bodySection .head-section-icon i {
  margin-right: 3px;
}
.work .bodySection .head-section-icon .icon {
  margin-right: 30px;
}
.work .bodySection .text-section {
  grid-area: text-section;
  max-width: 800px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin: 0px 0px 16px 0px;
}
.work .bodySection .contact-mail {
  margin-bottom: 16px;
  margin-left: 20px;
}
.work .bodySection .contact-mail i {
  margin-right: 5px;
}
.work .bodySection .apply-section {
  grid-area: apply-section;
  max-width: 800px;
}
.work .bodySection .apply-section .contact-person-image {
  max-width: 300px;
  margin-top: 16px;
  display: grid;
  justify-content: center;
}
.work .bodySection .apply-section .contact-person-image img {
  max-width: 100px;
  border-radius: 100%;
}
.work .bodySection .apply-section .contact-person {
  max-width: 300px;
  display: grid;
  justify-content: center;
}
.work .bodySection .apply-section .contact-person p {
  margin-bottom: 0px;
}

.notfound {
  display: grid;
  margin-top: 200px;
  margin-bottom: 200px;
  justify-self: center;
  align-self: center;
}
.notfound p {
  justify-self: center;
}

.thankyou {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.thankyou .thankyouimg {
  display: grid;
  justify-content: center;
  align-items: center;
}
.thankyou h1 {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
.thankyou p {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
.thankyou .socialicon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.partner {
  margin-bottom: 200px;
}
.partner .container {
  background: linear-gradient(90deg, #324E59 0%, #436977 100%);
  color: #000;
  display: grid;
  justify-items: center;
  align-self: center;
}
.partner .partner-list {
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.partner .partner-list .partner-card {
  display: grid;
  height: 200px;
  width: 350px;
  padding: 20px;
}
.partner .partner-list .partner-card .partner-text {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 8px;
  align-self: center;
  justify-content: center;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.4;
}
.partner .partner-list .partner-card img {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 8px;
  height: 200px;
  width: 300px;
}
@media (max-width: 780px) {
  .partner .partner-list .partner-card {
    display: grid;
    justify-content: center;
    height: 200px;
    width: 300px;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 680px) {
  .partner .partner-list .partner-card {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 580px) {
  .partner .partner-list .partner-card {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 920px) {
  .quoteslider {
    background: white;
    width: 920px;
    justify-self: center;
  }
  .quoteslider .quote-icon {
    color: #6DBFF2;
    font-size: 64px;
  }
  .quoteslider .inner-text {
    padding: 20px;
    font-weight: 600;
  }
  .quoteslider .row {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .quoteslider .each-slide-effect {
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-color: #324E59;
    border-radius: 20px;
    box-shadow: 0 0 6px 10px rgba(0, 0, 0, 0.06);
  }
  .quoteslider .each-slide-effect h3 {
    margin-top: 0px;
  }
  .quoteslider .each-slide-effect .slide-inner {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .quoteslider .each-slide-effect .carousel-image-container {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .quoteslider .each-slide-effect .carousel-image-container .carousel-image {
    border-radius: 50%;
    max-width: 20%;
    height: auto;
  }
  .quoteslider .each-slide-effect .carousel-image-container .carousel-image .carousel-image-text {
    margin-left: 15px;
  }
}
@media (max-width: 920px) {
  .quoteslider {
    display: none;
  }
}
.sectionempty .style-1 {
  min-height: 80px;
  background: #324E59;
  color: #fff;
}
.sectionempty .style-2 {
  min-height: 64px;
  background: #fff;
  color: #000;
}
.sectionempty .style-3 {
  min-height: 64px;
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionempty .style-4 {
  min-height: 64px;
  background: #fff;
  color: #000;
  text-align: justify;
}

.sectionimageleft .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionimageleft .style-2 {
  background: #fff;
  color: #000;
}
.sectionimageleft .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionimageleft .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionimageleft .sectionbody {
  display: grid;
  justify-items: center;
}
.sectionimageleft .cards {
  max-width: 1280px;
  margin: auto;
  display: grid;
  min-height: 400px;
  gap: 1rem;
  padding: 0vh 0vw 0vh 0vw;
}
@media (min-width: 920px) {
  .sectionimageleft .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "cardleft cardright cardright cardright";
  }
  .sectionimageleft .image img {
    max-width: 400px;
  }
}
@media (max-width: 920px) {
  .sectionimageleft .cards {
    grid-template-columns: 1fr;
    grid-template-areas: "cardleft" "cardright";
  }
  .sectionimageleft .cards .cardleft {
    grid-area: cardleft;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimageleft .cards .cardright {
    grid-area: cardright;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimageleft .image img {
    max-width: 300px;
    margin-top: 32px;
  }
}
.sectionimageleft .cardleft {
  grid-area: cardleft;
  display: grid;
  justify-content: start;
  align-items: center;
}
.sectionimageleft .cardright {
  grid-area: cardright;
  display: grid;
  justify-content: center;
  align-items: center;
}
.sectionimageleft .title {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5;
  padding-bottom: 16px;
  max-width: 600px;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageleft .text {
  font-weight: 400;
  max-width: 600px;
  padding-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageleft .image {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageleft .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionimageright .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionimageright .style-2 {
  background: #fff;
  color: #000;
}
.sectionimageright .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionimageright .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionimageright .cards {
  max-width: 1280px;
  margin: auto;
  display: grid;
  min-height: 400px;
  gap: 1rem;
  padding: 0vh 0vw 0vh 0vw;
}
@media (min-width: 920px) {
  .sectionimageright .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "cardleft cardleft cardright cardright";
  }
  .sectionimageright .image img {
    max-width: 400px;
  }
}
@media (max-width: 920px) {
  .sectionimageright .cards {
    grid-template-columns: 1fr;
    grid-template-areas: "cardright" "cardleft";
  }
  .sectionimageright .cards .cardleft {
    grid-area: cardleft;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimageright .cards .cardright {
    grid-area: cardright;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimageright .image img {
    max-width: 300px;
    margin-top: 32px;
  }
}
.sectionimageright .cardleft {
  grid-area: cardleft;
  display: grid;
  justify-content: center;
  align-items: center;
}
.sectionimageright .cardright {
  grid-area: cardright;
  display: grid;
  justify-content: end;
  align-items: center;
}
.sectionimageright .title {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5;
  padding-bottom: 16px;
  max-width: 600px;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageright .text {
  font-weight: 400;
  max-width: 600px;
  padding-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageright .image {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimageright .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionleftwithbutton .style-1 {
  background: #324E59;
  color: #fff;
  position: relative;
  margin-top: 85vh;
  z-index: 50;
}
.sectionleftwithbutton .style-2 {
  background: #fff;
  color: #000;
  position: relative;
  margin-top: 85vh;
  z-index: 50;
}
.sectionleftwithbutton .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
  position: relative;
  margin-top: 85vh;
  z-index: 50;
}
.sectionleftwithbutton .sectionbody {
  display: grid;
  justify-items: center;
}
.sectionleftwithbutton .cards {
  max-width: 1280px;
  display: grid;
  gap: 1rem;
  padding: 0vh 0vw 0vh 0vw;
  margin: 60px 0px 60px 0px;
}
.sectionleftwithbutton .cards button {
  margin-left: 20px;
  display: grid;
  justify-content: flex-end;
  display: block;
  display: grid;
}
@media (min-width: 800px) {
  .sectionleftwithbutton .cards {
    grid-template-columns: 1fr 3fr;
  }
}
.sectionleftwithbutton .title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 20px 16px 20px;
}
.sectionleftwithbutton .text {
  max-width: 600px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  margin: 0px 20px 32px 20px;
}
.sectionleftwithbutton .image {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionleftwithbutton .image img {
  max-width: 250px;
  max-height: 250px;
}
.sectionleftwithbutton .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionrightwithbutton .cards {
  max-width: 1280px;
  margin: auto;
  display: grid;
  gap: 1rem;
  padding: 6vh 0vw 6vh 0vw;
}
@media (min-width: 800px) {
  .sectionrightwithbutton .cards {
    grid-template-columns: 3fr 1fr;
  }
}
.sectionrightwithbutton .title {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5;
  padding-bottom: 16px;
}
.sectionrightwithbutton .text {
  font-weight: 400;
  max-width: 600px;
  padding-bottom: 32px;
}
.sectionrightwithbutton .image {
  display: grid;
  align-items: center;
  justify-content: center;
}
.sectionrightwithbutton .image img {
  max-width: 400px;
}
.sectionrightwithbutton .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionslidingbackground {
  background-image: url("/src/assets/images/backdrop/Slide2.jpg");
  z-index: 50;
  height: 60vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
}

@media (max-width: 991px) {
  .sectionslidingbackground {
    background-attachment: scroll;
  }
}
.sectiontext .style-1 {
  background: #324e59;
  color: #fff;
  padding: 2vh;
}
.sectiontext .style-2 {
  background: #fff;
  color: #000;
  padding: 2vh;
}
.sectiontext .style-3 {
  background: #6dbff2;
  background: linear-gradient(90deg, #6dbff2 0%, #ffffff 100%);
  color: #000;
  padding: 2vh;
}
.sectiontext .style-4 {
  background: linear-gradient(169deg, #c4e1f2 55%, #324e59 30%);
  color: #000;
  padding: 6vh;
  min-height: 74vh;
}
.sectiontext .style-5 {
  background: linear-gradient(169deg, #c4e1f2 30%, #f0f0f0 60%);
  color: #000;
  padding: 5vh 4vw 5vh 4vw;
  min-height: 74vh;
}
.sectiontext .style-6 {
  background: linear-gradient(169deg, #c4e1f2 30%, #f0f0f0 60%);
  color: #000;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 140px 4vw 60px 4vw;
}
.sectiontext .cards {
  max-width: 800px;
  margin: auto;
  display: grid;
  gap: 1rem;
  margin: 0 auto;
}
.sectiontext .card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #fff;
  border-radius: 25px;
  padding: 4vh 3vw 4vh 3vw;
  max-width: 880px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
}
.sectiontext .title {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectiontext .text {
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
}
.sectiontext .text p {
  margin: 0px auto 16px auto;
}
@media (min-width: 940px) {
  .sectiontext {
    text-align: justify;
  }
}
.sectiontext .image {
  display: grid;
  align-items: center;
  justify-content: center;
}
.sectiontext .image img {
  max-width: 250px;
  max-height: 250px;
}
.sectiontext .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionvideo .slogan {
  position: fixed;
  top: 30vmin;
  left: 0;
  right: 0;
  z-index: 20;
}
.sectionvideo .slogan-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;
  background-color: #C4E1F2;
  opacity: 50%;
}
.sectionvideo .slogan-text,
.sectionvideo .slogan-text a {
  position: relative;
  z-index: 22;
  padding: 5vh 0;
  color: #0f0f0f;
  font-size: 5.5vh;
  text-align: center;
  text-decoration: none;
  opacity: 90%;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionvideo .slogan-text a {
  width: 50%;
  align-items: center;
  float: left;
  display: block;
  margin: -5vh 0;
}
.sectionvideo .title {
  font-size: 54px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectionvideo .text {
  max-width: 600px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.4;
  margin: 0px auto 16px auto;
}
.sectionvideo .text p {
  margin: 0px auto 16px auto;
}
@media (max-width: 939px) {
  .sectionvideo .title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.2;
    margin: 0px 0px 16px 0px;
  }
}
@media (max-width: 600px) {
  .sectionvideo .slogan {
    top: 40vmin;
  }
}
.sectionvideo .background-video {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100vw;
  height: 110vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.sectionslogan {
  display: grid;
}
.sectionslogan img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  max-width: 100%;
}
.sectionslogan h1 {
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
  justify-self: center;
  align-self: center;
  margin-left: 50px;
  margin-right: 50px;
}
.sectionslogan .text-frame {
  grid-column: 1;
  grid-row: 1;
  min-height: 100px;
  margin-top: 19%;
  margin-bottom: 19%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #C4E1F2;
  opacity: 50%;
}

.cloudiumservice {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "image-top";
}
.cloudiumservice .image-top {
  grid-area: image-top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cloudiumservice .image-top img {
  justify-self: center;
  align-self: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  max-width: 100%;
}
.cloudiumservice .bodySection {
  justify-self: center;
  margin-bottom: 20px;
}
.cloudiumservice .bodySection .text-section {
  grid-area: text-section;
  max-width: 800px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.sectionimagelefttextright .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionimagelefttextright .style-2 {
  background: #fff;
  color: #000;
}
.sectionimagelefttextright .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionimagelefttextright .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionimagelefttextright .sectionbody {
  display: grid;
  justify-items: center;
}
.sectionimagelefttextright .cards {
  max-width: 1280px;
  display: grid;
  min-height: 400px;
  justify-self: center;
  margin: 60px 0px 60px 0px;
}
@media (min-width: 920px) {
  .sectionimagelefttextright .cards {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cardleft cardright";
  }
  .sectionimagelefttextright .image img {
    max-width: 400px;
  }
}
@media (max-width: 920px) {
  .sectionimagelefttextright .cards {
    grid-template-columns: 1fr;
    grid-template-areas: "cardleft" "cardright";
  }
  .sectionimagelefttextright .cards .cardleft {
    grid-area: cardleft;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimagelefttextright .cards .cardright {
    grid-area: cardright;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimagelefttextright .image img {
    max-width: 300px;
    margin: 0px 0px 16px 0px;
  }
}
.sectionimagelefttextright .cardleft {
  grid-area: cardleft;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}
.sectionimagelefttextright .cardright {
  grid-area: cardright;
  display: grid;
  justify-content: end;
  align-items: center;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}
.sectionimagelefttextright .title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectionimagelefttextright .text {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.4;
}
.sectionimagelefttextright .image {
  display: grid;
  align-items: center;
  justify-content: center;
}
.sectionimagelefttextright .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionimagerighttextleft .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionimagerighttextleft .style-2 {
  background: #fff;
  color: #000;
}
.sectionimagerighttextleft .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionimagerighttextleft .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionimagerighttextleft .sectionbody {
  display: grid;
  justify-items: center;
}
.sectionimagerighttextleft .cards {
  max-width: 1280px;
  display: grid;
  min-height: 400px;
  justify-self: center;
  margin: 60px 0px 60px 0px;
}
@media (min-width: 920px) {
  .sectionimagerighttextleft .cards {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cardleft cardright";
  }
  .sectionimagerighttextleft .image img {
    max-width: 400px;
  }
}
@media (max-width: 920px) {
  .sectionimagerighttextleft .cards {
    grid-template-columns: 1fr;
    grid-template-areas: "cardright" "cardleft";
  }
  .sectionimagerighttextleft .cards .cardleft {
    grid-area: cardleft;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimagerighttextleft .cards .cardright {
    grid-area: cardright;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .sectionimagerighttextleft .image img {
    max-width: 300px;
    margin: 0px 0px 16px 0px;
  }
}
.sectionimagerighttextleft .cardleft {
  grid-area: cardleft;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}
.sectionimagerighttextleft .cardright {
  grid-area: cardright;
  display: grid;
  justify-content: end;
  align-items: center;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}
.sectionimagerighttextleft .title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectionimagerighttextleft .text {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
}
.sectionimagerighttextleft .image {
  display: grid;
  align-items: center;
  justify-content: center;
}
.sectionimagerighttextleft .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionthreecolumns .style-1 {
  background: #324E59;
  color: #f0f0f0;
}
.sectionthreecolumns .style-2 {
  background: #fff;
  color: #000;
}
.sectionthreecolumns .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionthreecolumns .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionthreecolumns .sectionbody {
  display: grid;
  justify-items: center;
}
.sectionthreecolumns .columns {
  display: grid;
  justify-items: center;
  padding: 60px 0px 60px 0px;
  max-width: 1280px;
}
@media (min-width: 920px) {
  .sectionthreecolumns .columns {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: " . columnsectiontitle ." "columnleft columnmiddle columnright";
  }
  .sectionthreecolumns h2 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.2;
    margin: 0px 0px 16px 0px;
  }
  .sectionthreecolumns .columntitle {
    letter-spacing: 0px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
  }
  .sectionthreecolumns .columntext {
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 920px) {
  .sectionthreecolumns .columns {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "columnsectiontitle" "columnleft" "columnmiddle" "columnright";
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .sectionthreecolumns .columns .columnsectiontitle {
    grid-area: columnsectiontitle;
    display: grid;
  }
  .sectionthreecolumns .columns .columnleft {
    grid-area: columnleft;
    display: grid;
    padding-bottom: 16px;
  }
  .sectionthreecolumns .columns .columnmiddle {
    grid-area: columnmiddle;
    display: grid;
    padding-bottom: 16px;
  }
  .sectionthreecolumns .columns .columnright {
    grid-area: columnright;
    display: grid;
  }
  .sectionthreecolumns h2 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.2;
    margin: 0px 0px 16px 0px;
  }
  .sectionthreecolumns .columntitle {
    letter-spacing: 0px;
    max-width: 600px;
  }
  .sectionthreecolumns .columntext {
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
  }
}
.sectionthreecolumns .columnsectiontitle {
  grid-area: columnsectiontitle;
  display: grid;
  justify-content: center;
}
.sectionthreecolumns .columnleft {
  grid-area: columnleft;
  display: grid;
  justify-content: center;
}
.sectionthreecolumns .columnmiddle {
  grid-area: columnmiddle;
  display: grid;
  justify-content: center;
}
.sectionthreecolumns .columnright {
  grid-area: columnright;
  display: grid;
  justify-content: center;
}

.sectionherowithtext {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "image-top" "body-section";
  color: white;
}
.sectionherowithtext .image-top {
  grid-area: image-top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionherowithtext .image-top img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  position: static;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  height: 85vh;
}
.sectionherowithtext .body-section {
  justify-self: center;
  align-self: center;
  grid-area: body-section;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  width: 100%;
  max-width: 1280px;
}
.sectionherowithtext .body-section .title-section {
  grid-area: title-section;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: flex-start;
  align-content: center;
}
.sectionherowithtext .body-section .title-section h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectionherowithtext .body-section .text-section {
  grid-area: text-section;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 32px;
}

.sectionherowithtextandbutton .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionherowithtextandbutton .style-2 {
  background: #324E59;
  color: #f0f0f0;
}
.sectionherowithtextandbutton .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionherowithtextandbutton .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectionherowithtextandbutton .sectionbody {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "image-top" "body-section-background" "body-section";
}
.sectionherowithtextandbutton .body-section-background {
  grid-area: body-section-background;
  justify-self: center;
  align-self: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  width: 100%;
  height: 400px;
  left: 0;
  right: 0;
  background-color: #324E59;
  opacity: 80%;
}
@media (max-width: 939px) {
  .sectionherowithtextandbutton .body-section-background {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: #324E59;
    opacity: 80%;
  }
}
.sectionherowithtextandbutton .image-top {
  grid-area: image-top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionherowithtextandbutton .image-top img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  position: static;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  height: 85vh;
}
.sectionherowithtextandbutton .body-section {
  justify-self: center;
  align-self: center;
  grid-area: body-section;
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
  width: 100%;
  max-width: 1280px;
}
.sectionherowithtextandbutton .body-section .title-section {
  grid-area: title-section;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 660px;
}
.sectionherowithtextandbutton .body-section .title-section h1 {
  font-size: 54px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
@media (max-width: 939px) {
  .sectionherowithtextandbutton .body-section .title-section h1 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.2;
    margin: 0px 0px 16px 0px;
  }
}
.sectionherowithtextandbutton .body-section .text-section {
  grid-area: text-section;
  display: grid;
  justify-content: flex-start;
  align-content: center;
  max-width: 660px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 32px;
}
.sectionherowithtextandbutton .body-section .button-section {
  grid-area: button-section;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  max-width: 660px;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionherowithtextandbutton .body-section .button-section button {
  background: #6DBFF2;
  border: 2px solid #6DBFF2;
  color: #324E59;
}
.sectionherowithtextandbutton .body-section .button-section button:hover,
.sectionherowithtextandbutton .body-section .button-section button:active {
  background-color: initial;
  background-position: 0 0;
  font-weight: 600;
  color: #6DBFF2;
}
.sectionherowithtextandbutton .body-section .button-section button:active {
  opacity: 0.5;
}

.sectiontwocolumns .style-1 {
  background: #324E59;
  color: #fff;
}
.sectiontwocolumns .style-2 {
  background: #fff;
  color: #000;
}
.sectiontwocolumns .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectiontwocolumns .style-4 {
  background: #fff;
  color: #000;
  text-align: justify;
}
.sectiontwocolumns .columns {
  max-width: 1280px;
  margin: auto;
  display: grid;
}
@media (min-width: 920px) {
  .sectiontwocolumns .columns {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: " . columnsectiontitle ." "columnleft columnright";
  }
  .sectiontwocolumns .image img {
    max-width: 400px;
  }
}
@media (max-width: 920px) {
  .sectiontwocolumns .columns {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "columnsectiontitle" "columnleft" "columnright";
  }
  .sectiontwocolumns .columns .columnsectiontitle {
    grid-area: columnsectiontitle;
    display: grid;
  }
  .sectiontwocolumns .columns .columnleft {
    grid-area: columnleft;
    display: grid;
  }
  .sectiontwocolumns .columns .columnright {
    grid-area: columnright;
    display: grid;
    padding-bottom: 50px;
  }
  .sectiontwocolumns .image img {
    max-width: 300px;
    margin-top: 32px;
  }
}
.sectiontwocolumns .columnsectiontitle {
  grid-area: columnsectiontitle;
  display: grid;
  justify-content: center;
}
.sectiontwocolumns .columnleft {
  grid-area: columnleft;
  display: grid;
  justify-content: center;
}
.sectiontwocolumns .columnright {
  grid-area: columnright;
  display: grid;
  justify-content: center;
}
.sectiontwocolumns h2 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
}
.sectiontwocolumns .columntitle {
  letter-spacing: 0px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
}
.sectiontwocolumns .columntext {
  letter-spacing: 0px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
}
.sectiontwocolumns .image {
  display: grid;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
.sectiontwocolumns .actions {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.sectionimagecentertextcenter .style-1 {
  background: #324E59;
  color: #fff;
}
.sectionimagecentertextcenter .style-2 {
  background: #324E59;
  color: #f0f0f0;
}
.sectionimagecentertextcenter .style-3 {
  background: #6DBFF2;
  background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
  color: #000;
}
.sectionimagecentertextcenter .style-4 {
  background: #fff;
  color: #000;
}
.sectionimagecentertextcenter .sectionbody {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "image-top" "body-background" "body-section";
}
.sectionimagecentertextcenter .body-background {
  grid-area: body-background;
  justify-self: center;
  align-self: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: #324E59;
  opacity: 80%;
}
.sectionimagecentertextcenter .image-top {
  grid-area: image-top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionimagecentertextcenter .image-top img {
  justify-self: center;
  align-content: start;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  position: static;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  height: 60vh;
}
.sectionimagecentertextcenter .body-section {
  justify-self: center;
  align-self: center;
  margin: 16px 20px 16px 20px;
  grid-area: body-section;
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
  width: 100%;
  max-width: 640px;
}
.sectionimagecentertextcenter .body-section .title-section {
  grid-area: title-section;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.sectionimagecentertextcenter .body-section .title-section h2 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectionimagecentertextcenter .body-section .text-section-row {
  grid-area: text-section-row1;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
}

.sectioncontactform .style-1 {
  display: grid;
  justify-items: center;
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
}
.sectioncontactform h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin-bottom: 16px;
}
.sectioncontactform .h2 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 16px;
}
.sectioncontactform .h3 {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 16px;
}
.sectioncontactform .h4 {
  font-size: 24px;
  font-weight: 700;
}
.sectioncontactform .container {
  display: grid;
  justify-self: center;
  max-width: 640px;
  width: 100%;
  margin: 60px 0px 60px 0px;
  grid-template-columns: 1fr;
  padding-left: 20px;
  padding-right: 20px;
}
.sectioncontactform .container .title {
  margin-left: 20px;
  margin-right: 20px;
}
.sectioncontactform .container h2 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 0px 0px 16px 0px;
}
.sectioncontactform .container .form-label {
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
}
.sectioncontactform .container input {
  width: 97%;
  height: 30px;
  margin-top: 10px;
  border-radius: 1dvh;
  border-width: 1px;
  border-style: solid;
  border-color: #c2cdd1;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
}
.sectioncontactform .container input:focus {
  border-color: #6dbff2;
  outline-color: #6dbff2;
  outline-width: 4px;
  border-width: 3px;
}
.sectioncontactform .container textarea {
  width: 97%;
  height: 160px;
  margin-top: 10px;
  border-radius: 1dvh;
  border-width: 1px;
  border-style: solid;
  border-color: #c2cdd1;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
}
.sectioncontactform .container textarea:focus {
  border-color: #6dbff2;
  outline-color: #6dbff2;
  outline-width: 4px;
  border-width: 3px;
}
.sectioncontactform .container .button-full-width {
  margin-top: 32px;
  display: grid;
  display: block;
  display: grid;
  align-content: center;
  background: #324E59;
  border: 2px solid #324E59;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #e6e6e6;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  width: 100%;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.sectioncontactform .container .button-full-width:hover,
.sectioncontactform .container .button-full-width:active {
  background-color: initial;
  background-position: 0 0;
  font-weight: 600;
  color: #324E59;
}
.sectioncontactform .container .button-full-width:active {
  opacity: 0.5;
}

@media (min-width: 920px) {
  .card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #fff;
    border-radius: 25px;
    padding: 0vh 0vw 0vh 0vw;
    max-width: 880px;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 600px;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 920px) {
  .card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #fff;
    border-radius: 25px;
    padding: 0vh 0vw 0vh 0vw;
    max-width: 880px;
    display: grid;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    grid-template-columns: 1fr;
  }
}
.button {
  background: #324E59;
  border: 2px solid #324E59;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #e6e6e6;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button:hover,
.button:active {
  background-color: initial;
  background-position: 0 0;
  font-weight: 600;
  color: #324E59;
}

.button:active {
  opacity: 0.5;
}

