.sectionleftwithbutton {
    .style-1 {
        background: #324E59;
        color: #fff;
        position: relative;
        margin-top: 85vh;
        z-index: 50;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
        position: relative;
        margin-top: 85vh;
        z-index: 50;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
        position: relative;
        margin-top: 85vh;
        z-index: 50;
    }

    .sectionbody {
        display: grid;
        justify-items: center;
    }
    
    .cards {
        max-width: $section-max-width;
        display: grid;
        gap: 1rem;
        padding: 0vh 0vw 0vh 0vw;
        margin: $distance-section-star 0px $distance-section-end 0px;
    
        button {
            margin-left: 20px;
            display: grid;
            justify-content: flex-end;
            display: block;
            display: grid;
        }

    }
    
    @media (min-width: 800px) {
        .cards { grid-template-columns: 1fr 3fr; }
    }
    
    .title {
        font-size: $h2-font-size;
        font-weight: $h2-font-weight;
        letter-spacing: $h2-letter-spacing;
        line-height: $h2-line-height;
        margin: 0px $h2-margin $distance-title-to-text $h2-margin;
    }
    
    .text {
        max-width: $half-section-max-width;
        font-weight: $text-font-weight;
        font-size: $text-font-size;
        line-height: $text-line-height;
        margin: 0px $text-margin $distance-text-to-button $text-margin;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-left: $image-margin;
        margin-right: $image-margin;
    }
    
    .image img {
        max-width: 250px;
        max-height: 250px;
    }
    
    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }
}