.sectiontwocolumns {
    .style-1 {
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
        text-align: justify;
    }
    
    .columns {
        max-width: 1280px;
        margin: auto;
        display: grid;
    }
    
    @media (min-width: $breaking-point-resolution) {
        .columns {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 
            ' . columnsectiontitle .'
            'columnleft columnright'
            ;
        }

        .image img {
            max-width: $desktop-resolution-image-max-width;
        }
    }
    @media (max-width: $breaking-point-resolution) {
        .columns {
            grid-template-columns: repeat(1, 1fr);;
            grid-template-areas: 
            'columnsectiontitle'
            'columnleft'
            'columnright'
            ;

            .columnsectiontitle {
                grid-area: columnsectiontitle;
                display: grid;
            }

            .columnleft {
                grid-area: columnleft;
                display: grid;
            }
            
            .columnright {
                grid-area: columnright;
                display: grid;
                padding-bottom: 50px;
            }
        }

        .image img {
            max-width: $mobile-resolution-image-max-width;
            margin-top: 32px;
        }
    }
    
    .columnsectiontitle {
        grid-area: columnsectiontitle;
        display: grid;
        justify-content: center;
    }

    .columnleft {
        grid-area: columnleft;
        display: grid;
        justify-content: center;
    }
    
    .columnright {
        grid-area: columnright;
        display: grid;
        justify-content: center;
    }
    
    
    h2 {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: $h2-letter-spacing;
        padding-left: $h2-padding;
        padding-right: $h2-padding;
        max-width: $half-section-max-width;
    }

    .columntitle {
        letter-spacing: $h3-letter-spacing;
        padding-left: $h3-padding;
        padding-right: $h3-padding;
        max-width: $half-section-max-width;
    }
    
    .columntext {
        letter-spacing: $text-letter-spacing;
        font-weight: 400;
        padding-left: $text-padding;
        padding-right: $text-padding;
        max-width: $half-section-max-width;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
        padding-left: $image-padding;
        padding-right: $image-padding;
    }
   
    
    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }    
}