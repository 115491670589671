.sectionimagelefttextright {
    .style-1 {
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
        text-align: justify;
    }

    .sectionbody {
        display: grid;
        justify-items: center;
    }
    
    .cards {
        max-width: $max-width;
        display: grid;
        min-height: 400px;
        justify-self: center;
        margin: $distance-section-star 0px $distance-section-end 0px;
    }
    
    @media (min-width: 920px) {
        .cards {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            'cardleft cardright'
            ;
        }

        .image img {
            max-width: 400px;
        }
    }
    @media (max-width: 920px) {
        .cards {
            grid-template-columns: 1fr;
            grid-template-areas:
            'cardleft' 
            'cardright'
            ;

            .cardleft {
                grid-area: cardleft;
                display: grid;
                justify-content: center;
                align-items: center;
            }
            
            .cardright {
                grid-area: cardright;
                display: grid;
                justify-content: center;
                align-items: center;
            }
        }

        .image img {
            max-width: 300px;
            margin: 0px 0px $distance-image-to-title 0px;
        }
    }
    
    .cardleft {
        grid-area: cardleft;
        display: grid;
        justify-content: center;
        align-items: center;
        max-width: $half-section-max-width;
        padding-left: $text-padding;
        padding-right: $text-padding;
    }
    
    .cardright {
        grid-area: cardright;
        display: grid;
        justify-content: end;
        align-items: center;
        max-width: $half-section-max-width;
        padding-left: $text-padding;
        padding-right: $text-padding;
    }
    
    .title {
        font-size: $h2-font-size;
        font-weight: $h2-font-weight;
        letter-spacing: $h2-letter-spacing;
        line-height: $h2-line-height;
        margin: 0px 0px $distance-title-to-text 0px;
    }
    
    .text {
        font-weight: $text-font-weight;
        font-size: $text-font-size;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
    }

    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }
}