.contactinfo {
 
  
  .style-5 {
    background: linear-gradient(170deg, $color-lightgray 62%, #324e59 30%);
    color: #000;
    padding: $distance-section-star + 80px 4vw $distance-section-end 4vw;
    
    @media (max-width: $breaking-point-resolution-contact-page) {
      background: linear-gradient(170deg, $color-lightgray 82%, #324e59 30%);
    }
  }
  
  
  .filler {
    background-color: #324e59;
  }

  .content-body {
    display: grid;
    justify-content: center;
    margin-bottom: 250px;
  }
  
  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr);
    grid-gap: 1vw;
    max-width: $max-width;
  }
  
  @media (min-width: $breaking-point-resolution-contact-page) {
    
    .content-text {
      display: grid;
      justify-self: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      width: 600px;
      margin-top: 14px;
    }

    .content-top {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-start: 1;
      grid-row-end: 2;
      margin: 16px 0px $full-distance-section-star-and-section-end 0px;
    }
  
    .content-bottom {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      margin-bottom: 32px;
    }


  }
  
  @media (max-width: $breaking-point-resolution-contact-page) {
    .wrapper {
      grid-template-columns: 1fr;
      grid-template-areas: 
        'cardlecontactformft' 
        'content-text'
        ;

      .contactform {
        grid-area: cardlecontactformft;
        margin-bottom: $full-distance-section-star-and-section-end;
      }
    }

    .content-top {
      margin-bottom: 32px;
    }

    .content-bottom {
      margin-bottom: 32px;
    }
    .content-text {
      grid-area: content-text;
      grid-template-columns: 1fr;
      max-width: 600px;
      justify-self: center;
    }
  }
}