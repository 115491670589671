.sectionimageright {
    .style-1 {
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
    }
    
    .style-3 {
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        background: #fff;
        color: #000;
        text-align: justify;
    }
    
    .cards {
        max-width: 1280px;
        margin: auto;
        display: grid;
        min-height: 400px;
        gap: 1rem;
        padding: 0vh 0vw 0vh 0vw;
    }
    
    @media (min-width: 920px) {
        .cards {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas: 
            'cardleft cardleft cardright cardright'
            ;
        }

        .image img {
            max-width: 400px;
        }
    }
    @media (max-width: 920px) {
        .cards {
            grid-template-columns: 1fr;
            grid-template-areas: 
            'cardright'
            'cardleft'
            ;

            .cardleft {
                grid-area: cardleft;
                display: grid;
                justify-content: center;
                align-items: center;
            }
            
            .cardright {
                grid-area: cardright;
                display: grid;
                justify-content: center;
                align-items: center;
            }
        }

        .image img {
            max-width: 300px;
            margin-top: 32px;
        }
    }
    
    .cardleft {
        grid-area: cardleft;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    
    .cardright {
        grid-area: cardright;
        display: grid;
        justify-content: end;
        align-items: center;
    }
    
    .title {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: -1.5;
        padding-bottom: 16px;
        max-width: 600px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .text {
        font-weight: 400;
        max-width: 600px;
        padding-bottom: 32px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    
   
    
    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }
}