.thankyou {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
    
    .thankyouimg {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    
    h1 {
        display: grid;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    p {
        display: grid;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .socialicon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }
}