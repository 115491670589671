.sectioncontactform {
    
    .style-1 {
        display: grid;
        justify-items: center;
        background-color: $color-lightgray;
        width: 100%;
        height: 100%;
    }

    h1 {
        font-size: $h1-font-size;
        font-weight: $h1-font-weight;
        letter-spacing: $h1-letter-spacing;
        line-height: $h1-line-height;
        margin-bottom: $distance-title-to-text;
    }

    .h2 {
        font-size: $h2-font-size;
        font-weight: $h2-font-weight;
        letter-spacing: $h2-letter-spacing;
        line-height: $h2-line-height;
        margin-bottom: $distance-title-to-text;
    }

    .h3 {
        font-size: $h3-font-size;
        font-weight: $h3-font-weight;
        letter-spacing: $h3-letter-spacing;
        line-height: $h3-line-height;
        margin-bottom: $distance-title-to-text;
    }

    .h4 {
        font-size: 24px;
        font-weight: 700;
    }
    
    .container {
        display: grid;
        justify-self: center;
        max-width: 640px;
        width: 100%;
        margin: $distance-section-star 0px $distance-section-end 0px;
        grid-template-columns: 1fr;
        padding-left: 20px;
        padding-right: 20px;

        .title {
            margin-left: $section-margin;
            margin-right: $section-margin;
        }

        h2 {
            font-size: $h2-font-size;
            font-weight: $h2-font-weight;
            letter-spacing: $h2-letter-spacing;
            line-height: $h2-line-height;
            margin: 0px 0px $distance-title-to-text 0px;
        }

        .form-label {
            letter-spacing: $text-letter-spacing;
            font-weight: $text-font-weight;
            font-size: $text-font-size;
            line-height: $text-line-height;
        }

        input {
            width: 97%;
            height: 30px;
            margin-top: 10px;
            border-radius:1dvh;
            border-width: 1px;
            border-style: solid;
            border-color: #c2cdd1; 
            padding-left: 10px;
            font-size: $text-font-size;
            font-family: "Roboto", "Poppins", sans-serif;
        }

        input:focus {
            border-color: #6dbff2;
            outline-color: #6dbff2;
            outline-width: 4px;
            border-width: 3px;
        }

        textarea {
            width: 97%;
            height: 160px;
            margin-top: 10px;
            border-radius:1dvh;
            border-width: 1px;
            border-style: solid;
            border-color: #c2cdd1;
            padding-left: 10px;
            font-size: $text-font-size;
            font-family: "Roboto", "Poppins", sans-serif;
        }

        textarea:focus {
            border-color: #6dbff2;
            outline-color: #6dbff2;
            outline-width: 4px;
            border-width: 3px;
        }

        .button-full-width {
            margin-top: $distance-text-to-button;
            display: grid;
            display: block;
            display: grid;
            align-content: center;
            background: $color-theme-5;
            border: 2px solid $color-theme-5;
            border-radius: 100px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: $color-linear-gradient-dark;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            min-height: 40px;
            width: 100%;
            outline: 0;
            padding: 12px 14px;
            text-align: center;
            text-rendering: geometricprecision;
            text-transform: none;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            vertical-align: middle;
        }

        .button-full-width:hover,
        .button-full-width:active {
            background-color: initial;
            background-position: 0 0;
            font-weight: 600;
            color: $color-theme-5;
        }
        
        .button-full-width:active {
            opacity: .5;
        }
    }
}