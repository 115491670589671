@media (min-width: 920px) {
  .card {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      background-color: #fff;
      border-radius: 25px;
      padding: 0vh 0vw 0vh 0vw;  
      max-width: 880px;
      display: grid;
      align-items: center;
      justify-content: center;
      width: 600px;
      grid-template-columns: 1fr;
  }
}
@media (max-width: 920px) {
  .card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #fff;
    border-radius: 25px;
    padding: 0vh 0vw 0vh 0vw;  
    max-width: 880px;
    display: grid;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    grid-template-columns: 1fr;
  } 
}