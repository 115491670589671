.contactform {
    // .style-1 {
    //     margin-top: 0vh;
    // }

   
    justify-self: center;
    width: 100%;

    .container {
        grid-template-columns: 1fr;
        max-width: 600px;
        margin: 40px 40px 40px 40px;

        p {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        input {
            width: 97%;
            height: 30px;
            margin-top: 10px;
            border-radius:1dvh;
            border-width: 1px;
            border-style: solid;
            border-color: #c2cdd1; 
            padding-left: 10px;
            font-size: $text-font-size;
            font-family: "Roboto", "Poppins", sans-serif;
        }

        input:focus {
            border-color: #6dbff2;
            outline-color: #6dbff2;
            outline-width: 4px;
            border-width: 3px;
        }

        textarea {
            width: 97%;
            height: 160px;
            margin-top: 10px;
            border-radius:1dvh;
            border-width: 1px;
            border-style: solid;
            border-color: #c2cdd1;
            padding-left: 10px;
            font-size: $text-font-size;
            font-family: "Roboto", "Poppins", sans-serif;
        }

        textarea:focus {
            border-color: #6dbff2;
            outline-color: #6dbff2;
            outline-width: 4px;
            border-width: 3px;
        }

        .button-full-width {
            margin-top: $distance-text-to-button;
            display: grid;
            display: block;
            display: grid;
            background: $color-theme-5;
            border: 2px solid $color-theme-5;
            border-radius: 100px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: $color-linear-gradient-dark;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            min-height: 40px;
            width: 100%;
            outline: 0;
            padding: 12px 14px;
            text-align: center;
            text-rendering: geometricprecision;
            text-transform: none;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            vertical-align: middle;
        }

        .button-full-width:hover,
        .button-full-width:active {
            background-color: initial;
            background-position: 0 0;
            font-weight: 600;
            color: $color-theme-5;
        }
        
        .button-full-width:active {
            opacity: .5;
        }
    }
}