.work {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
    'image-top'
    'head-section-title'
    'head-section-icon'
    'text-section'
    'apply-section'
    ;
    
    h1 {
        font-size: $h1-font-size;
        font-weight: $h1-font-weight;
        letter-spacing: $h1-letter-spacing;
        line-height: $h1-line-height;
        margin: 0px 0px $distance-title-to-text 0px;
    }
    
    h2 {
        font-size: $h2-font-size;
        font-weight: $h2-font-weight;
        letter-spacing: $h2-letter-spacing;
        line-height: $h2-line-height;
        margin-bottom: $distance-title-to-text;
        margin: $full-distance-section-star-and-section-end 0px $distance-title-to-text 0px;
        padding-left: 20px;
    }

    p {
        font-size: $text-font-size;
        font-weight: $text-font-weight;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
        margin: $distance-title-to-text $text-margin $distance-title-to-text $text-margin;
    }

    li {
        font-size: $text-font-size;
        font-weight: $text-font-weight;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
        margin: $distance-title-to-text $text-margin $distance-title-to-text 0px;
    }

    .image-top {
        grid-area: image-top;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            justify-self: center;
            align-content: start;
            grid-column: 1;
            grid-row: 1;
            z-index: 1;
            position:static;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            height: 60vh;
        }
    }

    .bodySection {
        max-width: 1280px;
        justify-self: center;
        align-self: center;
        padding-top: $distance-section-star;
        padding-bottom: $distance-section-end;

        .head-section-title {
            grid-area: head-section-icon;
            max-width: 800px;
            display: flex;
            padding-left: 20px;
            padding-right: 20px;
        }

        .head-section-icon {
            grid-area: head-section-icon;
            max-width: 800px;
            display: flex;
            margin-left: 20px;
            margin-right: 20px;

            i {
                margin-right: 3px;
            }

            .icon {
                margin-right: 30px;
            }
        }
    
        .text-section {
            grid-area: text-section;
            max-width: 800px;
            display: flex;
            margin-left: $text-margin;
            margin-right: $text-margin;
            margin: 0px 0px $distance-title-to-text 0px;  
        }
        
        .contact-mail {
            margin-bottom: $distance-image-to-title;
            margin-left: 20px;
            i {
                margin-right: 5px;
            }
        }

        .apply-section {
            grid-area: apply-section;
            max-width: 800px;
            
            .contact-person-image {
                max-width: 300px;
                margin-top: $distance-image-to-title;
                display: grid;
                justify-content: center;

                img {
                    max-width: 100px;
                    border-radius: 100%;
                }
            }

            .contact-person {
                max-width: 300px;
                display: grid;
                justify-content: center;
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
}