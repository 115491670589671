.sectiontext {
    .style-1 {
        background: #324e59;
        color: #fff;
        padding: 2vh;
    }
    
    .style-2 {
        background: #fff;
        color: #000;
        padding: 2vh;
    }
    
    .style-3 {
        background: #6dbff2;
        background: linear-gradient(90deg, #6dbff2 0%, #ffffff 100%);
        color: #000;
        padding: 2vh;  
    }
    
    .style-4 {
        background: linear-gradient(169deg, #c4e1f2 55%, #324e59 30%);
        color: #000;
        padding: 6vh;
        min-height: 74vh;
    }
    
    .style-5 {
        background: linear-gradient(169deg, #c4e1f2 30%, #f0f0f0 60%);
        color: #000;
        padding: 5vh 4vw 5vh 4vw;
        min-height: 74vh;
    }
    
    .style-6 {
        background: linear-gradient(169deg, #c4e1f2 30%, #f0f0f0 60%);
        color: #000;
        display: grid;
        align-items: center;
        justify-content: center;
        padding: $distance-section-star + 80px 4vw $distance-section-end 4vw;    
    }

    

    .cards {
        max-width: 800px;
        margin: auto;
        display: grid;
        gap: 1rem;
        margin: 0 auto;
    }
      
    .card {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        background-color: #fff;
        border-radius: 25px;
        padding: 4vh 3vw 4vh 3vw;
        max-width: 880px;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr;
    }
    
    .title {
        font-size: $h1-font-size;
        font-weight: $h1-font-weight;
        letter-spacing: $h1-letter-spacing;
        line-height: $h1-line-height;
        margin: 0px 0px $distance-title-to-text 0px;
    }
    

    .text {
        letter-spacing: $text-letter-spacing;
        font-weight: $text-font-weight;
        font-size: $text-font-size;
        line-height: $text-line-height;

        p {
            margin: 0px auto $distance-text-to-text auto;
        }
    }

    @media (min-width: 940px) {
        text-align: justify;
    }
    
    .image {
        display: grid;
        align-items: center;
        justify-content: center;
    }
    
    .image img {
        max-width: 250px;
        max-height: 250px;
    }
    
    .actions {
        display: flex;
        gap: 1rem;
        justify-content: left;
    }
}