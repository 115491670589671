.sectionempty {
    .style-1 {
        min-height: 80px;
        background: #324E59;
        color: #fff;
    }
    
    .style-2 {
        min-height: 64px;
        background: #fff;
        color: #000;
    }
    
    .style-3 {
        min-height: 64px;
        background: #6DBFF2;
        background: linear-gradient(90deg, #6DBFF2 0%, #ffffff 100%);
        color: #000;
    }
    
    .style-4 {
        min-height: 64px;
        background: #fff;
        color: #000;
        text-align: justify;
    }
}